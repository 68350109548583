import { NewUpload } from "./actions";

export const getAuction = (state) => state.auctionModal.auction;
export const getAuctionModalOpen = (state) => state.auctionModal.openModal;
export const getAuctionUploadError = (state) => state.auctionModal.error;
export const getLinkedPost = (state) => state.auctionModal.linkedPost;
export const getAuctionModalState = (state) => state.auctionModal.currentState;
export const getUploadState = (state) => state.auctionModal.imageUploadState;
export const getUploadImageData = (state) =>
  state.auctionModal.uploadImageData as NewUpload;
export const getAuctionModalLoadingState = (state) =>
  state.auctionModal.loading;
export const getAuctionModalIsEditing = (state) => state.auctionModal.isEditing;
export const getAuctionLastStep = (state) => state.auctionModal.lastStepState;

export const getAuctionCurrentConnectionStep = (state) =>
  state.auctionModal.currentConnectionStep;
export const getAuctionLastConnectionStep = (state) =>
  state.auctionModal.lastConnectionStep;

export const getCurrentPath = (state) => state.auctionModal.path;
